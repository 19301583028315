import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f3ba1a',
                    color: '#515355',
                    borderRadius: '20px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#f5c43a',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f3ba1a"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F3BA19',
                    },
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#F3BA19', // here is where you set the color for checked state
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#F3BA19',
                    },
                },
            },
        },
    },
});

export default theme;
