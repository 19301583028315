import './Kitchenette.css'
import {Bloodtype, Chair, Flatware, Kitchen, LocalCafe, LocalDrink, Microwave, ViewQuilt} from "@mui/icons-material";

function Kitchenette() {
    return (
        <div className={"kitchenette"}>
            <div className={"kitchenette-section-1"}>
                <div className={"kitchenette-section-1-left"}>
                    <div className={"kitchenette-section-1-left-features"}>
                        <p className={"kitchenette-section-1-left-features-title"}>Features</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><Chair/>{"Cozy Lounge Area"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><Kitchen/>{"Refrigerator"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><Microwave/>{"Microwave"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><LocalDrink/>{"Water Fountain"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><LocalCafe/>{"Coffee Machine"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><Bloodtype/>{"Electric Kettle"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><ViewQuilt/>{"Drawers & Shelves"}</p>
                        <p className={"kitchenette-section-1-left-features-feature"}><Flatware/>{"Cutlery & Cups"}</p>
                        <p className={"kitchenette-section-1-left-features-attention"}></p>
                    </div>
                </div>
                <div className={"kitchenette-section-1-right"}>
                    <img src={"/kitchenette.png"} alt="Shared Office" className="kitchenette-section-1-right-image"/>
                    <div className="kitchenette-section-1-right-image-text">
                        Kitchenette
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kitchenette;