import './PhoneBox.css'
import {AcUnit, Bolt, ChairAlt, Coffee, MicOff, Power, Router, Wifi} from "@mui/icons-material";

function PhoneBox() {
    return (
        <div className={"phone-box"}>
            <div className={"phone-box-section-1"}>
                <div className={"phone-box-section-1-left"}>
                    <img src={"/phone-box.png"} alt="Meeting Room" className="phone-box-section-1-left-image"/>
                    <div className="phone-box-section-1-left-image-text">
                        Phone Box
                    </div>
                </div>
                <div className={"phone-box-section-1-right"}>
                    <div className={"phone-box-section-1-right-features"}>
                        <p className={"phone-box-section-1-right-features-title"}>Features</p>
                        <p className={"phone-box-section-1-right-features-feature"}><Wifi/>{"High Speed Internet"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><ChairAlt/>{"Ergonomic Furniture "}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><MicOff/>{"Acoustic Isolation"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><AcUnit/>{"Ventilation System"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><Power/>{"Integrated Power Outlets"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><Bolt/>{"Accessible Charging Points"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><Router/>{"Dedicated Wi-Fi Access Points"}</p>
                        <p className={"phone-box-section-1-right-features-feature"}><Coffee/>{"Coffee Machine"}</p>
                        <p className={"phone-box-section-1-right-features-attention"}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhoneBox;