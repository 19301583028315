import './Advantages.css'

const advantages = [
    {image: "/advantages-images/GreatPlaceToWork.png", text: "Great Place To Work"},
    {image: "/advantages-images/MeetingRoom.png", text: "Meeting Room"},
    {image: "/advantages-images/DiverseCommunity.png", text: "Diverse Community"},
    {image: "/advantages-images/HighSpeedInternet.png", text: "High Speed Internet"},
    {image: "/advantages-images/PrinterAndScanner.png", text: "Reprography"},
    {image: "/advantages-images/StrategicLocation.png", text: "Strategic Location"},
    {image: "/advantages-images/AffordablePrice.png", text: "Affordable Prices"},
    {image: "/advantages-images/QuietPlaceToWork.png", text: "Quiet Place to Work"}
];


function Advantages() {
    return (
        <div className={"advantages"}>
            <div className={"advantages-title"}>
                {"Advantages"}
                <div className="advantages-title-circle"></div>
            </div>
            <div className={"advantages-icons"}>
                <div className={"advantages-row"}>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[0].image} alt={advantages[0].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[0].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[1].image} alt={advantages[1].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[1].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[2].image} alt={advantages[2].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[2].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[3].image} alt={advantages[3].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[3].text}</p>
                    </div>
                </div>
                <div className={"advantages-row"}>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[4].image} alt={advantages[4].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[4].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[5].image} alt={advantages[5].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[5].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[6].image} alt={advantages[6].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[6].text}</p>
                    </div>
                    <div className={"advantages-row-advantage"}>
                        <img className={"advantages-row-advantage-icon"} src={advantages[7].image} alt={advantages[7].text}/>
                        <p className={"advantages-row-advantage-text"}>{advantages[7].text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advantages;