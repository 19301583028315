import './MeetingRoom.css'
import {CoffeeMaker, ErrorOutline, KeyboardVoice, Print, ReceiptLong, Tv, VideoCameraBack, VideoStable, Wifi} from "@mui/icons-material";

function MeetingRoom() {
    return (
        <div className={"meeting-room"}>
            <div className={"meeting-room-section-1"}>
                <div className={"meeting-room-section-1-left"}>
                    <div className={"meeting-room-section-1-left-features"}>
                        <p className={"meeting-room-section-1-left-features-title"}>Features</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><Wifi/>{"High Speed Internet"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><Tv/>{"TV Screen"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><VideoCameraBack/>{"WebCam"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><KeyboardVoice/>{"Speakers"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><VideoStable/>{"Video Projector"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><ReceiptLong/>{"Flip Chart"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><Print/>{"Printer & Scanner"}</p>
                        <p className={"meeting-room-section-1-left-features-feature"}><CoffeeMaker/>{"Coffee Machine"}</p>
                        <p className={"meeting-room-section-1-left-features-attention"}><ErrorOutline fontSize={"small"}/>The meeting room can accommodate up
                            to six guests</p>
                    </div>
                </div>
                <div className={"meeting-room-section-1-right"}>
                    <img src={"/meeting-room.png"} alt="Meeting Room" className="meeting-room-section-1-right-image"/>
                    <div className="meeting-room-section-1-right-image-text">
                        Meeting Room
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MeetingRoom;