import './Header.css'
import {Button} from "@mui/material";
import Avatar from '@mui/material/Avatar';

function Header({onRegisterClick}) {
    return (
        <div className={"header"}>
            <div className={"header-content"}>{/*we need to wrap the sticky content in a div in order to not a have direct link to the overflow-y: auto div*/}
                <div className={"header-content-left"}>
                    <Avatar
                        src="/mospace-logo.png"
                        alt="mospace-logo"
                        className="header-content-logo"
                        sx={{
                            width: 100,
                            height: '100%',
                            borderRadius: 0
                        }}
                    />
                </div>
                <div className={"header-content-right"}>
                    <Button className={"header-content-right-register-button"} variant={"contained"} size={"large"} onClick={onRegisterClick}>Register</Button>
                </div>
            </div>
        </div>
    );
}

export default Header;