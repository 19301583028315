import './App.css';
import Header from "./header/Header";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import Footer from "./footer/Footer";
import Advantages from "./advantages/Advantages";
import Prices from "./prices/Prices";
import Register from "./register/Register";
import Introduction from "./introduction/Introduction";
import Services from "./services/Services";
import React from 'react';

const RefHolder = React.forwardRef((props, ref) => (
    <div ref={ref}>
        <Register />
    </div>
));

function App() {
    const registerRef = React.useRef(null);

    const handleScrollToRegister = () => {
        registerRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Header onRegisterClick={handleScrollToRegister}/>
                <div className={"content"}>
                    <Introduction/>
                    <Advantages/>
                    <Services/>
                    <Prices/>
                    <RefHolder ref={registerRef} />
                    <Footer/>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default App;
