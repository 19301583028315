import './Prices.css'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Chip} from "@mui/material";

function Prices() {
    return (
        <div className={"prices"}>
            <div className={"prices-title"}>
                {"Prices"}
                <div className="prices-title-circle"></div>
            </div>
            <div className={"prices-cards"}>
                <div className={"prices-cards-card"} style={{background: 'linear-gradient(to bottom, #BC6B00 64px, #F1F1F1 50px)'}}>
                    <p className={"prices-cards-card-title"}>{"Daily Pass"}</p>
                    <div className={"prices-cards-card-price"}>
                        <p className={"prices-cards-card-price-original"}><s>{"100Dh"}</s></p>
                        <p className={"prices-cards-card-price-discount"}>{"50Dh"}</p>
                        <p className={"prices-cards-card-price-per-day"}>{"=50Dh/day"}</p>
                        <div className={"prices-cards-card-price-separator"}/>
                    </div>
                    <div className={"prices-cards-card-services"}>
                    <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"08 AM to 08 PM"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Ergonomic Furniture"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"High Speed Internet"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Printer And Scanner"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Coffee Machine"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Meeting Room"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Monday to Sunday"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Nominated Desk"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Private Locker"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Access To Events/Partnerships"}</p>
                    </div>
                </div>
                <div className={"prices-cards-card"} style={{background: 'linear-gradient(to bottom, #B7B7B7 64px, #F1F1F1 50px)'}}>
                    <p className={"prices-cards-card-title"}>{"Weekly Pass"}</p>
                    <div className={"prices-cards-card-price"}>
                        <p className={"prices-cards-card-price-original"}><s>{"500Dh"}</s></p>
                        <p className={"prices-cards-card-price-discount"}>{"250Dh"}</p>
                        <p className={"prices-cards-card-price-per-day"}>{"≈40Dh/day"}</p>
                        <div className={"prices-cards-card-price-separator"}/>
                    </div>
                    <div className={"prices-cards-card-services"}>
                    <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"08 AM to 08 PM"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Ergonomic Furniture"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"High Speed Internet"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Printer And Scanner"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Coffee Machine"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Meeting Room"}<Chip size={"small"} style={{marginLeft: '5px'}} label="5h"/></p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Monday to Sunday"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Nominated Desk"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Private Locker"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Access To Events/Partnerships"}</p>
                    </div>
                </div>
                <div className={"prices-cards-card"} style={{background: 'linear-gradient(to bottom, #E5AB1A 64px, #F1F1F1 50px)'}}>
                    <p className={"prices-cards-card-title"}>{"Monthly Pass"}</p>
                    <div className={"prices-cards-card-price"}>
                        <p className={"prices-cards-card-price-original"}><s>{"1800Dh"}</s></p>
                        <p className={"prices-cards-card-price-discount"}>{"800Dh"}</p>
                        <p className={"prices-cards-card-price-per-day"}>{"≈30Dh/day"}</p>
                        <div className={"prices-cards-card-price-separator"}/>
                    </div>
                    <div className={"prices-cards-card-services"}>
                    <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"08 AM to 08 PM"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Ergonomic Furniture"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"High Speed Internet"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Printer And Scanner"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Coffee Machine"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Meeting Room"}<Chip size={"small"} style={{marginLeft: '5px'}} label="20h"/></p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Monday to Sunday"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Nominated Desk"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Private Locker"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Access To Events/Partnerships"}</p>
                    </div>
                </div>
                <div className={"prices-cards-card"} style={{background: 'linear-gradient(to bottom, #04AFAA 64px, #F1F1F1 50px)'}}>
                    <p className={"prices-cards-card-title"}>{"Quarterly Pass"}</p>
                    <div className={"prices-cards-card-price"}>
                        <p className={"prices-cards-card-price-original"}><s>{"5000Dh"}</s></p>
                        <p className={"prices-cards-card-price-discount"}>{"2000Dh"}</p>
                        <p className={"prices-cards-card-price-per-day"}>{"≈25Dh/day"}</p>
                        <div className={"prices-cards-card-price-separator"}/>
                    </div>
                    <div className={"prices-cards-card-services"}>
                    <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"08 AM to 08 PM"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Ergonomic Furniture"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"High Speed Internet"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Printer And Scanner"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Coffee Machine"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Meeting Room"}<Chip size={"small"} style={{marginLeft: '5px'}} label="60h"/></p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Monday to Sunday"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Nominated Desk"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Private Locker"}</p>
                        <p className={"prices-cards-card-services-service-ko"}><CloseIcon/>{"Access To Events/Partnerships"}</p>
                    </div>
                </div>
                <div className={"prices-cards-card"} style={{background: 'linear-gradient(to bottom, #782ADD 64px, #F1F1F1 50px)'}}>
                    <p className={"prices-cards-card-title"}>{"Annual Pass"}</p>
                    <div className={"prices-cards-card-price"}>
                        <p className={"prices-cards-card-price-original"}><s>{"16000Dh"}</s></p>
                        <p className={"prices-cards-card-price-discount"}>{"7000Dh"}</p>
                        <p className={"prices-cards-card-price-per-day"}>{"≈20Dh/day"}</p>
                        <div className={"prices-cards-card-price-separator"}/>
                    </div>
                    <div className={"prices-cards-card-services"}>
                    <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"08 AM to 08 PM"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Ergonomic Furniture"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"High Speed Internet"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Printer And Scanner"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Coffee Machine"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Meeting Room"}<Chip size={"small"} style={{marginLeft: '5px'}} label="Unlimited"/></p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Monday to Sunday"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Nominated Desk"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Private Locker"}</p>
                        <p className={"prices-cards-card-services-service-ok"}><CheckIcon/>{"Access To Events/Partnerships"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prices;