import './SharedOffice.css'
import {Bolt, Coffee, Desk, Power, Print, Receipt, Router, Wifi} from "@mui/icons-material";

function SharedOffice() {
    return (
        <div className={"shared-office"}>
            <div className={"shared-office-section-1"}>
                <div className={"shared-office-section-1-left"}>
                    <img src={"/shared-office.png"} alt="Shared Office" className="shared-office-section-1-left-image"/>
                    <div className="shared-office-section-1-left-image-text">
                        Shared Office
                    </div>
                </div>
                <div className={"shared-office-section-1-right"}>
                    <div className={"shared-office-section-1-right-features"}>
                        <p className={"shared-office-section-1-right-features-title"}>Features</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Wifi/>{"High Speed Internet"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Desk/>{"Shared Desk (140 cm x 70 cm)"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Router/>{"Dedicated Wi-Fi Access Points"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Power/>{"Integrated Power Outlets"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Bolt/>{"Accessible Charging Points"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Receipt/>{"Flip Chart"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Print/>{"Printer & Scanner"}</p>
                        <p className={"shared-office-section-1-right-features-feature"}><Coffee/>{"Coffee Machine"}</p>
                        <p className={"shared-office-section-1-right-features-attention"}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SharedOffice;