import './Footer.css'
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Link} from "@mui/material";

function Footer() {
    const location = '80 Avenue Attahrir, 2éme étage, El Jadida';
    const phoneNumber = "05 23 34 30 10";
    const emailAddress = "contact@mospace.online";

    return (
        <div className={"footer"}>
            <div className={"footer-left"}>
                <img className={"footer-left-logo"} src={"/mospace-logo.png"} alt={"mospace-logo"}/>
            </div>
            <div className={"footer-center"}>
                <span>CONTACT US ON</span>
                <div>
                    <FacebookIcon onClick={() => window.open('https://www.facebook.com/mospace.ma', '_blank')}/>
                    <InstagramIcon/>
                </div>
                <div>
                    <LinkedInIcon/>
                    <WhatsAppIcon/>
                </div>
            </div>
            <div className={"footer-right"}>
                <div className={"footer-right-block"}>
                    <div className={"footer-right-info-row"}>
                        <LocationOnIcon/>
                        <Link href={`https://maps.google.com/?q=${encodeURIComponent(location)}`} target="_blank">
                            {location}
                        </Link>
                    </div>
                    <div className={"footer-right-info-row"}>
                        <LocalPhoneIcon/>
                        <Link href={`tel:${phoneNumber}"`}>
                            {phoneNumber}
                        </Link>
                    </div>
                    <div className={"footer-right-info-row"}>
                        <EmailIcon/>
                        <Link href={`mailto:${emailAddress}`}>
                            {emailAddress}
                        </Link>
                    </div>
                    <div className={"footer-right-info-row"}>
                        <AccessTimeIcon/>
                        <span>Lundi à Samedi, De 8h à 20h</span>
                    </div>
                </div>
            </div>
            <div className={"footer-mentions"}>
                <Link href="#">Conditions Générales d'utilisation</Link>
                <Link href="#">Politique de confidentialité</Link>
            </div>
        </div>
    );
}

export default Footer;