import './Introduction.css'
import {Button} from "@mui/material";

function Introduction() {
    return (
        <div className={"introduction"}>
            <div className={"introduction-left"}>
                <div className={"introduction-left-title"}>
                    Create, Connect, Collaborate
                </div>
                <div className={"introduction-left-sub-title"}>
                    Your Inspiring Coworking Space in El Jadida
                </div>
                <div className={"introduction-left-description"}>
                    Explore a dynamic professional environment where creativity and collaboration come to life.
                    Our coworking space goes beyond flexible offices, providing an inspiring community to nurture your professional development.
                    Join us and make every workday a productive and stimulating adventure.
                </div>
                <div className={"introduction-left-actions"}>
                    <Button className={"introduction-left-actions-discover"} variant={"contained"} size={"large"}>
                        Discover MoSpace
                    </Button>
                </div>
                <div className={"introduction-left-reviews"}>
                    <div className={"introduction-left-reviews-images"}></div>
                    <div className={"introduction-left-reviews-description"}>
                        + 30 Members (Entrepreneurs, Freelancers, Students & More)
                    </div>
                </div>
            </div>
            <div className={"introduction-right"}>
                <img src={"/introduction.jpg"} alt={"mospace"}/>
            </div>

        </div>
    );
}

export default Introduction;