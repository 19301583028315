import './Register.css'
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import React from "react";
import {Send} from "@mui/icons-material";

export default function Register() {

    const [state, setState] = React.useState({
        meetingRoom: false,
        phoneBox: false,
        monitorKeyboardMouse: false,
        printerScanner: false
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <div className={"register"}>
            <div className={"register-title"}>
                {"Register"}
                <div className="register-title-circle"></div>
            </div>
            <div className={"register-registration"}>
                <img className={"register-registration-image"} src={"./registration-form.png"} alt={"Registration"}/>
                <div className={"register-registration-form"}>
                    <div className={"register-registration-form-row"}>
                        <TextField id="name" label="Name" variant="outlined"/>
                        <TextField id="email" label="Email" variant="outlined"/>
                        <TextField id="phone" label="Phone" variant="outlined"/>
                    </div>
                    <div className={"register-registration-form-row"}>
                        <FormControl>
                            <FormLabel id="professional-status-label">Professional Status</FormLabel>
                            <RadioGroup aria-labelledby="professional-status-label" defaultValue="other" name="professional-status-group">
                                <FormControlLabel value="student" control={<Radio/>} label="Student"/>
                                <FormControlLabel value="entrepreneur" control={<Radio/>} label="Entrepreneur"/>
                                <FormControlLabel value="employee" control={<Radio/>} label="Employee"/>
                                <FormControlLabel value="freelancer" control={<Radio/>} label="Freelancer"/>
                                <FormControlLabel value="other" control={<Radio/>} label="Other"/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="type-of-subscription-label">Professional Status</FormLabel>
                            <RadioGroup aria-labelledby="type-of-subscription-label" defaultValue="monthly" name="type-of-subscription-group">
                                <FormControlLabel value="weekly" control={<Radio/>} label="Weekly"/>
                                <FormControlLabel value="monthly" control={<Radio/>} label="Monthly"/>
                                <FormControlLabel value="quartely" control={<Radio/>} label="Quartely"/>
                                <FormControlLabel value="annual" control={<Radio/>} label="Annual"/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                            <FormLabel component="legend">Services</FormLabel>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={state.meetingRoom} onChange={handleChange} name="meetingRoom"/>}
                                                  label="Meeting Room"/>
                                <FormControlLabel control={<Checkbox checked={state.phoneBox} onChange={handleChange} name="phoneBox"/>} label="Phone Box"/>
                                <FormControlLabel control={<Checkbox checked={state.monitorKeyboardMouse} onChange={handleChange} name="monitorKeyboardMouse"/>}
                                                  label="Monitor/Keyboard/Mouse"/>
                                <FormControlLabel control={<Checkbox checked={state.printerScanner} onChange={handleChange} name="printerScanner"/>}
                                                  label="Printer Scanner"/>
                            </FormGroup>
                        </FormControl>
                    </div>
                    <div className={"send-registration-form-button-container"} >
                        <Button className={"send-registration-form-button"} variant="contained" endIcon={<Send/>}>Submit</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}