import './Services.css'
import React from "react";
import MeetingRoom from "./meeting-room/MeetingRoom";
import SharedOffice from "./shared-office/SharedOffice";
import PhoneBox from "./phone-box/PhoneBox";
import Kitchenette from "./kitchenette/Kitchenette";

function Services() {
    return (
        <div className={"services"}>
            <div className={"services-title"}>
                {"Services"}
                <div className="services-title-circle"></div>
            </div>
            <SharedOffice/>
            <MeetingRoom/>
            <PhoneBox/>
            <Kitchenette/>
        </div>
    );
}

export default Services;